import Pagination from 'rc-pagination/lib/locale/ga_IE';
import DatePicker from '../date-picker/locale/ga_IE';
import TimePicker from '../time-picker/locale/ga_IE';
import Calendar from '../calendar/locale/ga_IE';
export default {
    locale: 'ga_IE',
    Pagination,
    DatePicker,
    TimePicker,
    Calendar,
    Table: {
        filterTitle: 'Scagaire roghchlár',
        filterConfirm: 'Ceart go leor',
        filterReset: 'Athshocraigh',
        selectAll: 'Roghnaigh an leathanach reatha',
        selectInvert: 'Inbhéartaigh an leathanach reatha',
    },
    Modal: {
        okText: 'Ceart go leor',
        cancelText: 'Cealaigh',
        justOkText: 'Ceart go leor',
    },
    Popconfirm: {
        okText: 'Ceart go leor',
        cancelText: 'Cealaigh',
    },
    Transfer: {
        searchPlaceholder: 'Cuardaigh anseo',
        itemUnit: 'mír',
        itemsUnit: 'míreanna',
    },
    Upload: {
        uploading: 'Uaslódáil ...',
        removeFile: 'Bain comhad',
        uploadError: 'Earráid uaslódáil',
        previewFile: 'Comhad réamhamhairc',
        downloadFile: 'Íoslódáil an comhad',
    },
    Empty: {
        description: 'Gan aon sonraí',
    },
};
